import "../assets/scss/soft-design-system-pro.scss"

import "../assets/js/core/popper.min.js"
import "../assets/js/core/bootstrap.min.js"
import "../assets/js/plugins/perfect-scrollbar.min.js"
import "../assets/js/plugins/typedjs.js"
import "../assets/js/plugins/parallax.min.js"
import "../assets/js/plugins/nouislider.min.js"
import "../assets/js/plugins/glidejs.min.js"
import "../assets/js/plugins/anime.min.js"
import "../assets/js/plugins/chartjs.min.js"
import "../assets/js/soft-design-system-pro.min.js"
import "../assets/js/plugins/tilt.min.js"
