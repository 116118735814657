import "index.css"
import "print.scss"
import "syntax-highlighting.css"
import "bridgetown-quick-search/dist"
import "theme.js"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

console.info("Bridgetown is loaded!")
